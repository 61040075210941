import React from "react";
import Bio from "../component/Bio/Bio";
import Layout from "../component/Layout/Layout";
import Seo from "../component/seo";
import SidebarLeft from "../component/Sidebar/SidebarLeft";
import SidebarRight from "../component/Sidebar/SidebarRight";
import { useSiteUrl } from "../utils";

export default function CreatorPage({ location }) {
  const canonicalUrl = `${useSiteUrl()}${location.pathname}`;

  return (
    <Layout>
      <Seo
        titleSample={true}
        title="About Author"
        canonicalUrl={canonicalUrl}
      />

      <SidebarLeft />
      <Bio />
      <SidebarRight />
    </Layout>
  );
}
